import React from 'react';

// Components
import { FormSection } from '..';

const Virtual = () => {
  return <FormSection title="" />;
};

export default Virtual;
