const FEE_ADMIN: string = 'ADMIN';
const FEE_ACCOMM: string = 'ACCOMM';
const FEE_AIRPORT: string = 'AIRPORT';

const STORAGE_KEY = 'ice_bookings';

export const constants = {
  FEE_ADMIN,
  FEE_ACCOMM,
  FEE_AIRPORT,
  STORAGE_KEY
};
