import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

interface IMUITextField extends StandardTextFieldProps {
  error?: boolean;
  errorText?: string;
}

const useStyle = makeStyles({
  formControl: {
    marginBottom: '0 !important',
    width: '100%'
  }
});

const MUITextField: React.FC<IMUITextField> = ({
  error,
  errorText,
  label,
  ...rest
}) => {
  const classes = useStyle();

  return (
    <FormControl className={classes.formControl} error={error}>
      <TextField {...rest} error={error} label={label} fullWidth />
      {error ? <FormHelperText>{errorText}</FormHelperText> : undefined}
    </FormControl>
  );
};

export default MUITextField;
