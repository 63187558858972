import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components
import { FormSection, MUISelect, MUIDatePicker } from '../';

// Context
import AppContext from '../../Contexts/AppContext';
import { appStateManager } from '../../state/app-state';

const useStyle = makeStyles({
    form: {
        margin: 'auto',
        width: '95%',
    },
    formInput: {
        marginBottom: '20px',
    },
});

const Courses = () => {
    const { state, dispatch } = useContext<IAppContext>(AppContext);
    const classes = useStyle();
    const { t } = useTranslation();

    const handleCourseChanged = ({ target }: any) => {
        dispatch({
            type: appStateManager.ACTION_COURSE_CHANGED,
            payload: +target.value,
        });
    };

    const handleCourseTypeChanged = ({ target }: any) => {
        dispatch({
            type: appStateManager.ACTION_COURSE_TYPE_CHANGED,
            payload: +target.value,
        });
    };

    const handleDateChanged = (date: any) => {
        dispatch({
            type: appStateManager.ACTION_DATE_CHANGED,
            payload: date,
        });
    };

    const handleLocationChanged = ({ target }: any) => {
        dispatch({
            type: appStateManager.ACTION_LOCATION_CHANGED,
            payload: +target.value,
        });
    };

    const handleWeekChanged = ({ target }: any) => {
        dispatch({
            type: appStateManager.ACTION_WEEK_CHANGED,
            payload: +target.value,
        });
    };

    return (
        <FormSection
            title={t('stepper_courses')}
            total={state.selectedCourseData.price}
        >
            <div className={classes.form}>
                <MUISelect
                    className={classes.formInput}
                    dataSource={state.dataSources.courseTypes}
                    displayExpr="name"
                    label={t('courses_course_type')}
                    onChange={handleCourseTypeChanged}
                    value={state.selectedCourseData.courseType}
                    valueExpr="courseTypeId"
                />
                <MUISelect
                    className={classes.formInput}
                    dataSource={state.dataSources.locations}
                    displayExpr="name"
                    label={t('courses_location')}
                    onChange={handleLocationChanged}
                    value={state.selectedCourseData.location}
                    valueExpr="locationId"
                />
                <MUISelect
                    className={classes.formInput}
                    dataSource={state.dataSources.courses}
                    displayExpr="name"
                    label={t('courses_course')}
                    onChange={handleCourseChanged}
                    value={state.selectedCourseData.course}
                    valueExpr="courseId"
                />
                <MUISelect
                    className={classes.formInput}
                    dataSource={state.dataSources.courseWeeks}
                    displayExpr="description"
                    label={t('courses_weeks')}
                    onChange={handleWeekChanged}
                    value={state.selectedCourseData.week}
                    valueExpr="week"
                />
                <MUIDatePicker
                    className={classes.formInput}
                    label={`${t('courses_start_date')} ${
                        state.selectedCourseData.isJunior &&
                        !state.selectedCourseData.isOnline
                            ? `${t('courses_start_date_arrival_day')}`
                            : ''
                    }`}
                    minDate={state.courseDates.startDate}
                    onChange={handleDateChanged}
                    shouldDisableDate={(p: any) => {
                        const current = moment(p).startOf('day');

                        if (state.selectedCourseData.isVirtual) {
                            const endDate = moment(
                                state.courseDates.endDate
                            ).startOf('day');
                            if (current.isAfter(endDate)) return true;
                            return current.isoWeekday() !== 1;
                        }

                        // if (
                        //     state.selectedCourseData.isJunior &&
                        //     state.selectedCourseData.isResidential
                        // ) {
                        //     const endDate = moment(
                        //         state.courseDates.endDate
                        //     ).startOf('day');
                        //     if (current.isSameOrAfter(endDate)) return true;
                        //     return current.day() !== 0;
                        // }

                        if (
                            state.selectedCourseData.isOnline &&
                            state.selectedCourseData.isJunior
                        ) {
                            const endDate = moment(
                                state.courseDates.endDate
                            ).startOf('day');
                            if (current.isSameOrAfter(endDate)) return true;
                            return current.isoWeekday() !== 1;
                        }

                        if (state.selectedCourseData.isJunior) {
                            const startDate = moment(
                                state.courseDates.startDate
                            ).startOf('day');
                            const endDate = moment(
                                state.courseDates.endDate
                            ).startOf('day');

                            if (state.selectedCourseData.isResidential) {
                                return !(
                                    current.isSameOrAfter(startDate) &&
                                    current
                                        .add(state.selectedCourseData.week, 'week')
                                        .isSameOrBefore(endDate) &&
                                    current.day() === 0
                                );
                            }

                            return !(
                                current.isSameOrAfter(startDate) &&
                                current
                                    .add(state.selectedCourseData.week, 'week')
                                    .isSameOrBefore(endDate)
                            );
                        }

                        const year = current.year();
                        const startDate = moment
                            .parseZone(new Date(year, 11, 16))
                            .startOf('day');
                        const endDate = moment
                            .parseZone(new Date(year, 0, 3))
                            .startOf('day');

                        console.log(
                            'state.selectedCourseData.isResidential',
                            state.selectedCourseData.isResidential
                        );

                        return (
                            current.isSameOrAfter(startDate) ||
                            current.isSameOrBefore(endDate) ||
                            (state.selectedCourseData.isPartTime &&
                                current.day() !== 2 &&
                                current.day() !== 4)
                        );
                    }}
                    value={state.selectedCourseData.date}
                ></MUIDatePicker>
            </div>
        </FormSection>
    );
};

export default Courses;
