import dd from '../courses.json';
import moment from 'moment';
import momentBusinessDays from 'moment-business-days';
import nationalitiesData from '../nationalities.json';
import { constants } from '../utils';
import { FilterMode } from './../utils/enums';

const initialState: IAppState = {
    appInitialised: false,
    validationErrors: [],
    nationalities: nationalitiesData,
    appData: {
        courseTypes: [],
        locations: [],
        courses: [],
        courseWeeks: [],
        fees: [],
        filterMode: FilterMode.All,
    },
    courseDates: {
        startDate: new Date(),
        endDate: new Date(),
    },
    dataSources: {
        accommTypes: dd.accommodation,
        accommWeeks: [],
        courses: [],
        courseTypes: [],
        dates: [],
        locations: [],
        transfers: dd.transfers,
        courseWeeks: [],
    },
    validation: {
        accommStartDate: { isValid: true, errorText: '' },
        dob: { isValid: true, errorText: '' },
        email: { isValid: true, errorText: '' },
        familyName: { isValid: true, errorText: '' },
        firstName: { isValid: true, errorText: '' },
        nationality: { isValid: true, errorText: '' },
        phone: { isValid: true, errorText: '' },
        guardianName: { isValid: true, errorText: '' },
        guardianAddress: { isValid: true, errorText: '' },
        guardianMobilePhone: { isValid: true, errorText: '' },
        guardianEmail: { isValid: true, errorText: '' },
        guardianVerifyEmail: { isValid: true, errorText: '' },
    },
    selectedAccommData: {
        accommFee: 0,
        price: 0,
        type: dd.accommodation[0].id,
        startDate: null,
        endDate: null,
        specialRequirements: '',
        week: null,
    },
    selectedCourseData: {
        accommodationIncluded: true,
        adminFee: dd.locations[0].courseTypes[0].adminFee,
        course: 0,
        courseType: 0,
        date: new Date(),
        isAcademicYear: false,
        isJunior: false,
        isPartTime: false,
        location: 0,
        locationDesc: '',
        price: 0,
        week: 0,
        endDate: undefined,
        isResidential: false,
        isVirtual: false,
        isOnline: false,
        allowAccomm: false,
    },
    selectedTransferData: {
        transfer: dd.transfers[0].id,
        price: dd.transfers[0].price,
    },
    studentData: {
        gender: 'female',
        firstName: '',
        familyName: '',
        dob: null,
        nationality: '',
        email: '',
        phone: '',
        guardianName: '',
        guardianAddress: '',
        guardianHomePhone: '',
        guardianMobilePhone: '',
        guardianEmail: '',
        guardianVerifyEmail: '',
    },
    guardianData: {
        name: '',
        address: '',
        homePhone: '',
        mobilePhone: '',
        email: '',
        verifyEmail: '',
    },
    requestObject: {
        booking_id: 0,
        session_id: '',
        location: '',
        course_type: '',
        course: '',
        num_weeks: 0,
        start_date: '',
        end_date: '',
        accomm_type: '',
        airport_transfers: 'Not Required',
        first_name: '',
        family_name: '',
        nationality: '',
        email: '',
        phone: '',
        admin_fee: 0,
        course_price: 0,
        accomm_price: 0,
        accomm_fee: 0,
        airport_transfer_fee: 0,
        gender: 'Female',
        guardian_name: '',
        guardian_address: '',
        guardian_home_phone: '',
        guardian_mobile_phone: '',
        guardian_email: '',
        duration: '',
        pre_arrival_fluency_builder: false,
        post_course_fluency_plus: false,
        one_to_one: false,
        hoodie_on_arrival: false,
        extras_price: 0,
    },
    extras: {
        preArrivalFluencyBuilder: {
            selected: false,
            price: 118,
        },
        postCourseFluencyPlus: {
            selected: false,
            price: 118,
        },
        oneToOne: {
            selected: false,
            price: 240,
        },
        hoodieOnArrival: {
            selected: false,
            price: 40,
        },
    },
};

const ACTION_ACCOMM_START_DATE_CHANGED = 'ACTION_ACCOMM_START_DATE';
const ACTION_ACCOMM_TYPE_CHANGED = 'ACTION_ACCOMM_TYPE_CHANGED';
const ACTION_ACCOMM_WEEKS_CHANGED = 'ACTION_ACCOMM_WEEKS_CHANGED';
const ACTION_COURSE_CHANGED = 'ACTION_COURSE_CHANGED';
const ACTION_COURSE_TYPE_CHANGED = 'ACTION_COURSE_TYPE_CHANGED';
const ACTION_DATE_CHANGED = 'ACTION_DATE_CHANGED';
const ACTION_DOB_CHANGED = 'ACTION_DOB_CHANGED';
const ACTION_EMAIL_CHANGED = 'ACTION_EMAIL_CHANGED';
const ACTION_FIRST_NAME_CHANGED = 'ACTION_FIRST_NAME_CHANGED';
const ACTION_FAMILY_NAME_CHANGED = 'ACTION_FAMILY_NAME_CHANGED';
const ACTION_LOCATION_CHANGED = 'ACTION_LOCATION_CHANGED';
const ACTION_NATIONALITY_CHANGED = 'ACTION_NATIONALITY_CHANGED';
const ACTION_PHONE_CHANGED = 'ACTION_PHONE_CHANGED';
const ACTION_SPECIAL_REQ_CHANGED = 'ACTION_SPECIAL_REQ_CHANGED';
const ACTION_GENDER_CHANGED = 'ACTION_GENDER_CHANGED';
const ACTION_TRANSFER_CHANGED = 'ACTION_TRANSFER_CHANGED';
const ACTION_WEEK_CHANGED = 'ACTION_WEEK_CHANGED';
const ACTION_GUARDIAN_NAME_CHANGED = 'ACTION_GUARDIAN_NAME_CHANGED';
const ACTION_GUARDIAN_ADDRESS_CHANGED = 'ACTION_GUARDIAN_ADDRESS_CHANGED';
const ACTION_GUARDIAN_HOME_PHONE_CHANGED = 'ACTION_GUARDIAN_HOME_PHONE_CHANGED';
const ACTION_GUARDIAN_MOBILE_PHONE_CHANGED =
    'ACTION_GUARDIAN_MOBILE_PHONE_CHANGED';
const ACTION_GUARDIAN_EMAIL_CHANGED = 'ACTION_GUARDIAN_EMAIL_CHANGED';
const ACTION_GUARDIAN_VERIFY_EMAIL_CHANGED =
    'ACTION_GUARDIAN_VERIFY_EMAIL_CHANGED';
const VALIDATE_FIELDS = 'VALIDATE_FIELDS';
const ACTION_OPTIONAL_EXTRA_CHANGED = 'ACTION_OPTIONAL_EXTRA_CHANGED';

const INIT_DATA = 'INIT_DATA';
const INIT_DATA_STORAGE = '';

const reducer = (state: IAppState, action: IAction): IAppState => {
    let courseTypes: ICourseType[] = [];
    let course: any = {};
    let courses: ICourse[] = [];
    let courseWeeks: ICourseWeek[] = [];
    let locations: ILocation[] = [];
    let endDate: Date | null;
    let accommEndDate: string | null = null;
    let fee = 0;
    let airportTransferFee = 0;
    let courseEndDate: string;
    let adminFee: number = 0;

    switch (action.type) {
        case ACTION_ACCOMM_START_DATE_CHANGED:
            const accommStartDate = moment(action.payload).format('DD/MM/YYYY');
            endDate = moment(action.payload)
                .add(-1, 'day')
                .add(state.selectedAccommData.week || 0, 'week')
                .toDate();
            accommEndDate = moment(endDate).format('DD/MM/YYYY');

            return {
                ...state,
                selectedAccommData: {
                    ...state.selectedAccommData,
                    startDate: action.payload,
                    endDate,
                },
                requestObject: {
                    ...state.requestObject,
                    accomm_start_date: accommStartDate,
                    accomm_end_date: accommEndDate,
                },
                validation: {
                    ...state.validation,
                    accommStartDate: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_ACCOMM_TYPE_CHANGED:
            const type = state.dataSources.accommTypes.find(
                (t) => t.id === action.payload
            )!;

            console.log('TYPE', type);

            if (type.id === 1) {
                return {
                    ...state,
                    selectedAccommData: initialState.selectedAccommData,
                    requestObject: {
                        ...state.requestObject,
                        accomm_fee: initialState.requestObject.accomm_fee,
                        accomm_price: initialState.requestObject.accomm_price,
                        accomm_start_date:
                            initialState.requestObject.accomm_start_date,
                        accomm_end_date:
                            initialState.requestObject.accomm_end_date,
                        accomm_type: initialState.requestObject.accomm_type,
                        accomm_num_weeks:
                            initialState.requestObject.accomm_num_weeks,
                        special_requirements:
                            initialState.requestObject.special_requirements,
                    },
                };
            }

            return {
                ...state,
                dataSources: {
                    ...state.dataSources,
                    accommWeeks: type.weeks,
                },
                selectedAccommData: {
                    ...state.selectedAccommData,
                    accommFee: +state.appData.fees.find(
                        (a) => a.feeCode === constants.FEE_ACCOMM
                    )!.fee,
                    price: type.weeks[0].price,
                    type: action.payload,
                    week: type.weeks[0].week,
                },
                requestObject: {
                    ...state.requestObject,
                    accomm_fee: +state.appData.fees.find(
                        (a) => a.feeCode === constants.FEE_ACCOMM
                    )!.fee,
                    accomm_num_weeks: type.weeks[0].week,
                    accomm_price: type.weeks[0].price,
                    accomm_type: type.name,
                },
            };
        case ACTION_ACCOMM_WEEKS_CHANGED:
            const selectedWeek = state.dataSources.accommWeeks.find(
                (w) => w.week === action.payload
            )!;

            endDate = state.selectedAccommData.startDate
                ? moment(state.selectedAccommData.startDate)
                      .add(-1, 'day')
                      .add(selectedWeek!.week, 'week')
                      .toDate()
                : null;

            accommEndDate = endDate
                ? moment(endDate).format('DD/MM/YYYY')
                : null;

            return {
                ...state,
                selectedAccommData: {
                    ...state.selectedAccommData,
                    endDate,
                    price: selectedWeek.price,
                    week: selectedWeek.week,
                },
                requestObject: {
                    ...state.requestObject,
                    accomm_end_date: accommEndDate,
                    accomm_num_weeks: selectedWeek.week,
                    accomm_price: selectedWeek.price,
                },
            };
        case ACTION_SPECIAL_REQ_CHANGED:
            return {
                ...state,
                selectedAccommData: {
                    ...state.selectedAccommData,
                    specialRequirements: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    special_requirements: action.payload,
                },
            };
        case ACTION_COURSE_TYPE_CHANGED:
            const courseType = filterData<ICourseType>(
                state.appData.courseTypes,
                'CourseType',
                action.payload
            )[0];
            locations = filterData<ILocation>(
                state.appData.locations,
                'Location',
                courseType.courseTypeId
            );
            courses = filterData<ICourse>(
                state.appData.courses,
                'Course',
                locations[0].locationId
            );
            courseWeeks = filterData<ICourseWeek>(
                state.appData.courseWeeks,
                'CourseWeek',
                courses[0].courseId
            );

            adminFee = +state.appData.fees.find(
                (a) => a.feeCode === constants.FEE_ADMIN
            )!.fee;

            airportTransferFee = calcTransferFee(
                state.selectedTransferData.transfer,
                locations[0].transferFee
            );

            courseEndDate = calcEndDate(
                courseType.isJunior,
                courses[0].startDate,
                courseWeeks[0].week
            );

            return {
                ...state,
                courseDates: {
                    startDate: courses[0].startDate,
                    endDate: courses[0].endDate,
                },
                dataSources: {
                    ...state.dataSources,
                    locations,
                    courses,
                    courseWeeks,
                },
                requestObject: {
                    ...state.requestObject,
                    accomm_fee: 0,
                    accomm_price: 0,
                    accomm_start_date: undefined,
                    accomm_end_date: undefined,
                    accomm_type: '',
                    accomm_num_weeks: undefined,
                    special_requirements: '',
                    admin_fee:
                        !courseType.isJunior &&
                        !courses[0].isAcademicYear &&
                        !courses[0].isOnline &&
                        !courses[0].isPartTime
                            ? adminFee
                            : 0,
                    course_type: courseType.name,
                    location: locations[0].name,
                    course: courses[0].name,
                    start_date: moment(courses[0]!.startDate).format(
                        'DD/MM/YYYY'
                    ),
                    end_date: courseEndDate,
                    num_weeks: courseWeeks[0].week,
                    course_price: courseWeeks[0].price,
                    airport_transfer_fee: airportTransferFee,
                    duration: courseWeeks[0].description,
                },
                selectedAccommData: initialState.selectedAccommData,
                selectedCourseData: {
                    ...state.selectedCourseData,
                    adminFee:
                        !courseType.isJunior &&
                        !courses[0].isAcademicYear &&
                        !courses[0].isOnline &&
                        !courses[0].isPartTime
                            ? adminFee
                            : 0,
                    courseType: courseType.courseTypeId,
                    location: locations[0].locationId,
                    locationDesc: locations[0].description,
                    course: courses[0].courseId,
                    date:
                        !courses[0].isVirtual &&
                        courses[0].isOnline &&
                        courseType.isJunior
                            ? moment()
                                  .startOf('isoWeek')
                                  .add(1, 'week')
                                  .toDate()
                            : courses[0].startDate,
                    week: courseWeeks[0].week,
                    isAcademicYear: courses[0].isAcademicYear,
                    isJunior: courseType.isJunior,
                    isPartTime: courses[0].isPartTime,
                    price: +courseWeeks[0].price,
                    isResidential: locations[0].isResidential,
                    isVirtual: courses[0].isVirtual,
                    isOnline: courses[0].isOnline,
                    allowAccomm: courses[0].allowAccomm,
                },
                selectedTransferData: {
                    ...state.selectedTransferData,
                    price: +airportTransferFee,
                },
            };
        case ACTION_LOCATION_CHANGED:
            const location = state.appData.locations.find(
                (loc) => loc.locationId === action.payload
            );

            airportTransferFee = calcTransferFee(
                state.selectedTransferData.transfer,
                location!.transferFee
            );

            courses = filterData<ICourse>(
                state.appData.courses,
                'Course',
                action.payload
            );
            courseWeeks = filterData<ICourseWeek>(
                state.appData.courseWeeks,
                'CourseWeek',
                courses[0].courseId
            );

            adminFee = +state.appData.fees.find(
                (a) => a.feeCode === constants.FEE_ADMIN
            )!.fee;

            courseEndDate = calcEndDate(
                state.selectedCourseData.isJunior,
                courses[0].startDate,
                courseWeeks[0].week
            );

            return {
                ...state,
                courseDates: {
                    startDate: courses[0].startDate,
                    endDate: courses[0].endDate,
                },
                dataSources: {
                    ...state.dataSources,
                    courses,
                    courseWeeks,
                },
                requestObject: {
                    ...state.requestObject,
                    admin_fee:
                        !state.selectedCourseData.isJunior &&
                        !courses[0].isAcademicYear &&
                        !courses[0].isOnline &&
                        !courses[0].isPartTime
                            ? adminFee
                            : 0,
                    location: location!.description,
                    course: courses[0].name,
                    start_date: moment(courses[0]!.startDate).format(
                        'DD/MM/YYYY'
                    ),
                    end_date: courseEndDate,
                    num_weeks: courseWeeks[0].week,
                    course_price: courseWeeks[0].price,
                    airport_transfer_fee: airportTransferFee,
                    duration: courseWeeks[0].description,
                },
                selectedCourseData: {
                    ...state.selectedCourseData,
                    adminFee:
                        !state.selectedCourseData.isJunior &&
                        !courses[0].isAcademicYear &&
                        !courses[0].isOnline &&
                        !courses[0].isPartTime
                            ? adminFee
                            : 0,
                    location: action.payload,
                    locationDesc: location!.description,
                    isAcademicYear: courses[0].isAcademicYear,
                    isPartTime: courses[0].isPartTime,
                    course: courses[0].courseId,
                    date: courses[0].startDate || new Date(),
                    week: courseWeeks[0].week,
                    price: +courseWeeks[0].price,
                    isResidential: location!.isResidential,
                    isVirtual: courses[0].isVirtual,
                    isOnline: courses[0].isOnline,
                    allowAccomm: courses[0].allowAccomm,
                },
                selectedTransferData: {
                    ...state.selectedTransferData,
                    price: airportTransferFee,
                },
            };
        case ACTION_COURSE_CHANGED:
            course = state.appData.courses.find(
                (c) => c.courseId === action.payload
            )! as ICourse;

            courseWeeks = state.appData.courseWeeks.filter(
                (w) => w.courseId === action.payload
            );

            adminFee = +state.appData.fees.find(
                (a) => a.feeCode === constants.FEE_ADMIN
            )!.fee;

            courseEndDate = calcEndDate(
                state.selectedCourseData.isJunior,
                course.startDate,
                courseWeeks[0].week
            );

            return {
                ...state,
                courseDates: {
                    startDate: course.startDate,
                    endDate: course.endDate,
                },
                dataSources: {
                    ...state.dataSources,
                    courseWeeks,
                },
                selectedCourseData: {
                    ...state.selectedCourseData,
                    adminFee:
                        !state.selectedCourseData.isJunior &&
                        !course.isAcademicYear &&
                        !course.isOnline &&
                        !course.isPartTime
                            ? adminFee
                            : 0,
                    course: action.payload,
                    isAcademicYear: course.isAcademicYear,
                    isPartTime: course.isPartTime,
                    date: course.startDate || new Date(),
                    week: courseWeeks[0].week,
                    price: +courseWeeks[0].price,
                    isVirtual: course.isVirtual,
                    isOnline: course.isOnline,
                    allowAccomm: course.allowAccomm,
                },
                requestObject: {
                    ...state.requestObject,
                    admin_fee:
                        !state.selectedCourseData.isJunior &&
                        !course.isAcademicYear &&
                        !course.isOnline &&
                        !course.isPartTime
                            ? adminFee
                            : 0,
                    course: course.name,
                    start_date: moment(course.startDate || new Date()).format(
                        'DD/MM/YYYY'
                    ),
                    end_date: courseEndDate,
                    num_weeks: courseWeeks[0].week,
                    course_price: +courseWeeks[0].price,
                    duration: courseWeeks[0].description,
                },
            };
        case ACTION_WEEK_CHANGED:
            course = state.appData.courses.find(
                (c) => c.courseId === state.selectedCourseData.course
            );

            const courseWeek = state.appData.courseWeeks.find(
                (w) =>
                    w.courseId === course!.courseId && w.week === action.payload
            );

            courseEndDate = calcEndDate(
                course!.isJunior,
                course!.startDate,
                action.payload
            );

            return {
                ...state,
                courseDates: {
                    startDate: course!.startDate,
                    endDate: course!.endDate,
                },
                selectedCourseData: {
                    ...state.selectedCourseData,
                    week: action.payload,
                    price: +courseWeek!.price,
                },
                requestObject: {
                    ...state.requestObject,
                    end_date: courseEndDate,
                    num_weeks: action.payload,
                    course_price: +courseWeek!.price,
                    duration: courseWeek!.description,
                },
            };
        case ACTION_DATE_CHANGED:
            courseEndDate = calcEndDate(
                state.selectedCourseData.isJunior,
                action.payload,
                state.selectedCourseData.week
            );

            return {
                ...state,
                selectedCourseData: {
                    ...state.selectedCourseData,
                    date: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    start_date: moment(action.payload).format('DD/MM/YYYY'),
                    end_date: courseEndDate,
                },
            };
        case ACTION_TRANSFER_CHANGED:
            const transfer = state.dataSources.transfers.find(
                (a) => a.id === action.payload
            )!;

            fee = state.appData.locations.find(
                (l) => l.locationId === state.selectedCourseData.location
            )!.transferFee;

            airportTransferFee = calcTransferFee(action.payload, fee);

            return {
                ...state,
                selectedTransferData: {
                    transfer: action.payload,
                    price: +airportTransferFee,
                },
                requestObject: {
                    ...state.requestObject,
                    airport_transfers: transfer!.name,
                    airport_transfer_fee: airportTransferFee,
                },
            };
        case ACTION_GENDER_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    gender: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    gender: action.payload === 'female' ? 'Female' : 'Male',
                },
            };
        case ACTION_FIRST_NAME_CHANGED:
            return {
                ...state,
                validation: {
                    ...state.validation,
                    firstName: {
                        isValid: true,
                        errorText: '',
                    },
                },
                studentData: {
                    ...state.studentData,
                    firstName: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    first_name: action.payload,
                },
                validationErrors: [],
            };
        case ACTION_FAMILY_NAME_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    familyName: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    family_name: action.payload,
                },
                validation: {
                    ...state.validation,
                    familyName: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_DOB_CHANGED:
            const dob = moment(action.payload).format('DD/MM/YYYY');
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    dob: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    dob,
                },
                validation: {
                    ...state.validation,
                    dob: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_NATIONALITY_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    nationality: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    nationality: action.payload,
                },
                validation: {
                    ...state.validation,
                    nationality: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_EMAIL_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    email: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    email: action.payload,
                },
                validation: {
                    ...state.validation,
                    email: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_PHONE_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    phone: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    phone: action.payload,
                },
                validation: {
                    ...state.validation,
                    phone: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_GUARDIAN_NAME_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    guardianName: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    guardian_name: action.payload,
                },
                validation: {
                    ...state.validation,
                    guardianName: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_GUARDIAN_ADDRESS_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    guardianAddress: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    guardian_address: action.payload,
                },
                validation: {
                    ...state.validation,
                    guardianAddress: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_GUARDIAN_HOME_PHONE_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    guardianHomePhone: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    guardian_home_phone: action.payload,
                },
            };
        case ACTION_GUARDIAN_MOBILE_PHONE_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    guardianMobilePhone: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    guardian_mobile_phone: action.payload,
                },
                validation: {
                    ...state.validation,
                    guardianMobilePhone: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case ACTION_GUARDIAN_EMAIL_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    guardianEmail: action.payload,
                },
                requestObject: {
                    ...state.requestObject,
                    guardian_email: action.payload,
                },
                validation: {
                    ...state.validation,
                    guardianEmail: {
                        isValid: true,
                        errorText: '',
                    },
                },
            };
        case ACTION_GUARDIAN_VERIFY_EMAIL_CHANGED:
            return {
                ...state,
                studentData: {
                    ...state.studentData,
                    guardianVerifyEmail: action.payload,
                },
                validation: {
                    ...state.validation,
                    guardianVerifyEmail: {
                        isValid: true,
                        errorText: '',
                    },
                },
                validationErrors: [],
            };
        case INIT_DATA:
            const appData = {
                ...action.payload,
                courses: action.payload.courses.map((course: any) => ({
                    ...course,
                    startDate: moment.parseZone(course.startdate).toDate(),
                    endDate: moment.parseZone(course.enddate).toDate(),
                })),
            } as IAppData;

            courseTypes = appData.courseTypes;

            let index = 0;
            if (appData.filterMode === FilterMode.Adult) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Adult English Course'
                );
            }
            if (appData.filterMode === FilterMode.Junior) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Junior Summer Camp'
                );
            } else if (appData.filterMode === FilterMode.Group) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Adult Group Online Classes'
                );
            } else if (appData.filterMode === FilterMode.IndividualOnline) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Individual Online Coaching'
                );
            } else if (appData.filterMode === FilterMode.JuniorOnline) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Junior Online Tutorials'
                );
            } else if (appData.filterMode === FilterMode.KidsVirtualCamps) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Kids Virtual Online Camps'
                );
            } else if (appData.filterMode === FilterMode.TeenVirtualCamps) {
                index = getCourseTypeIndex(
                    appData.courseTypes,
                    'Teen Virtual Online Camps'
                );
            }

            locations = filterData<ILocation>(
                appData.locations,
                'Location',
                courseTypes[index].courseTypeId
            );
            courses = filterData<ICourse>(
                appData.courses,
                'Course',
                locations[0].locationId
            );
            courseWeeks = filterData<ICourseWeek>(
                appData.courseWeeks,
                'CourseWeek',
                courses[0].courseId
            );

            courseEndDate = calcEndDate(
                courseTypes[index].isJunior,
                courses[0].startDate,
                courseWeeks[0].week
            );

            return {
                ...state,
                appData,
                appInitialised: true,
                courseDates: {
                    startDate: courses[0].startDate,
                    endDate: courses[0].endDate,
                },
                dataSources: {
                    ...state.dataSources,
                    courseTypes,
                    locations,
                    courses,
                    courseWeeks,
                },
                requestObject: {
                    ...state.requestObject,
                    admin_fee:
                        !courseTypes[index].isJunior &&
                        !courses[0].isAcademicYear &&
                        !courses[0].isOnline &&
                        !courses[0].isPartTime
                            ? +appData.fees.find(
                                  (fee) => fee.feeCode === constants.FEE_ADMIN
                              )!.fee
                            : 0,
                    course_type: courseTypes[index].name,
                    location: locations[0].name,
                    course: courses[0].name,
                    num_weeks: courseWeeks[0].week,
                    start_date: moment(courses[0]!.startDate).format(
                        'DD/MM/YYYY'
                    ),
                    end_date: courseEndDate,
                    course_price: +courseWeeks[0].price,
                    duration: courseWeeks[0].description,
                },
                selectedCourseData: {
                    ...state.selectedCourseData,
                    adminFee:
                        !courseTypes[index].isJunior &&
                        !courses[0].isAcademicYear &&
                        !courses[0].isOnline &&
                        !courses[0].isPartTime
                            ? +appData.fees.find(
                                  (fee) => fee.feeCode === constants.FEE_ADMIN
                              )!.fee
                            : 0,
                    courseType: courseTypes[index].courseTypeId,
                    isJunior: courseTypes[index].isJunior,
                    isPartTime: courses[0].isPartTime,
                    location: locations[0].locationId,
                    locationDesc: locations[0].description,
                    course: courses[0].courseId,
                    week: courseWeeks[0].week,
                    price: +courseWeeks[0].price,
                    isResidential: locations[0].isResidential,
                    isVirtual: courses[0].isVirtual,
                    isOnline: courses[0].isOnline,
                    allowAccomm: courses[0].allowAccomm,
                },
            };
        case INIT_DATA_STORAGE:
            const app = {
                ...action.payload.appData,
                courses: action.payload.appData.courses.map((course: any) => ({
                    ...course,
                    startDate: moment.parseZone(course.startdate).toDate(),
                    endDate: moment.parseZone(course.enddate).toDate(),
                })),
            } as IAppData;
            const storage = action.payload.storageData;

            courseTypes = app.courseTypes;
            locations = filterData<ILocation>(
                app.locations,
                'Location',
                storage.courseType
            );
            courses = filterData<ICourse>(
                app.courses,
                'Course',
                storage.location
            );
            course = courses.find(
                (c) => c.courseId === storage.course
            )! as ICourse;
            courseWeeks = filterData<ICourseWeek>(
                app.courseWeeks,
                'CourseWeek',
                storage.course
            );

            courseEndDate = calcEndDate(
                storage.isJunior,
                course.startDate,
                storage.week
            );

            return {
                ...state,
                appData: app,
                appInitialised: true,
                courseDates: {
                    startDate: courses.find(
                        (c) => c.courseId === storage.course
                    )!.startDate,
                    endDate: courses.find((c) => c.courseId === storage.course)!
                        .endDate,
                },
                dataSources: {
                    ...state.dataSources,
                    courseTypes: app.courseTypes,
                    locations,
                    courses,
                    courseWeeks,
                },
                selectedCourseData: {
                    ...state.selectedCourseData,
                    accommodationIncluded: storage.accommodationIncluded,
                    adminFee: storage.adminFee,
                    course: storage.course,
                    courseType: storage.courseType,
                    date: courses.find((c) => c.courseId === storage.course)!
                        .startDate,
                    isAcademicYear: storage.isAcademicYear,
                    isJunior: storage.isJunior,
                    isPartTime: storage.isPartTime,
                    location: storage.location,
                    locationDesc: storage.locationDesc,
                    price: storage.price,
                    week: storage.week,
                    isResidential: locations.find(
                        (l) => l.locationId === storage.location
                    )!.isResidential,
                    isVirtual: storage.isVirtual,
                    isOnline: storage.isOnline,
                    allowAccomm: storage.allowAccomm,
                },
                requestObject: {
                    ...state.requestObject,
                    location: locations.find(
                        (loc) => loc.locationId === storage.location
                    )!.name,
                    course: courses.find(
                        (course) => course.courseId === storage.course
                    )!.name,
                    course_type: courseTypes.find(
                        (type) => type.courseTypeId === storage.courseType
                    )!.name,
                    num_weeks: storage.week,
                    admin_fee: storage.adminFee,
                    course_price: storage.price,
                    start_date: moment(
                        courses.find((c) => c.courseId === storage.course)!
                            .startDate
                    ).format('DD/MM/YYYY'),
                    end_date: courseEndDate,
                    duration: storage.description,
                },
            };
        case VALIDATE_FIELDS:
            const errors = action.payload;
            let myObj = {};

            errors.map((m: any) => {
                return (myObj = {
                    ...myObj,
                    [m.field]: {
                        isValid: false,
                        errorText: m.errorText,
                    },
                });
            });

            return {
                ...state,
                validationErrors: errors.map((m: any) => m.errorText),
                validation: {
                    ...state.validation,
                    ...myObj,
                },
            };
        case ACTION_OPTIONAL_EXTRA_CHANGED: {
            const { field, value }: { field: string; value: any } =
                action.payload;

            let requestObjField = 'pre_arrival_fluency_builder';

            switch (field) {
                case 'postCourseFluencyPlus':
                    requestObjField = 'post_course_fluency_plus';
                    break;
                case 'oneToOne':
                    requestObjField = 'one_to_one';
                    break;
                case 'hoodieOnArrival':
                    requestObjField = 'hoodie_on_arrival';
                    break;
            }
            return {
                ...state,
                extras: {
                    ...state.extras,
                    [field]: {
                        selected: value,
                        //@ts-ignore
                        price: initialState.extras[field].price,
                    },
                },
                requestObject: {
                    ...state.requestObject,
                    [requestObjField]: value,
                    //@ts-ignore
                    extras_price: value
                        ? state.requestObject.extras_price +
                          initialState.extras[field].price
                        : state.requestObject.extras_price -
                          initialState.extras[field].price,
                },
            };
        }
        default:
            return state;
    }
};

const filterData = <T>(
    data: T[],
    type: 'CourseType' | 'Location' | 'Course' | 'CourseWeek',
    id: number
) => {
    switch (type) {
        case 'CourseType':
            return data.filter((x: any) => x.courseTypeId === id);
        case 'Location':
            return data.filter((x: any) => x.courseTypeId === id);
        case 'Course':
            return data.filter((x: any) => x.locationId === id);
        case 'CourseWeek':
            return data.filter((x: any) => x.courseId === id);
        default:
            return [];
    }
};

const calcTransferFee = (transferId: number, fee: number): number => {
    if (transferId === 2) {
        return +(fee * 2);
    } else if (transferId > 2) {
        return +fee;
    } else {
        return 0;
    }
};

const calcEndDate = (isJunior: boolean, startDate: Date, weeks: number) => {
    if (isJunior) return '';

    const s = moment(startDate);
    const holStart = moment(new Date(s.year(), 11, 16));
    const holEnd = moment(new Date(s.year() + 1, 0, 3));
    const holDuration = moment.duration(holEnd.diff(holStart)).asDays() + 1;

    // let courseEndDate = s.clone().add(-1, 'day').add(weeks, 'weeks').add(-1, 'day');
    let courseEndDate = momentBusinessDays(startDate)
        .clone()
        .businessSubtract(1)
        .businessAdd(5 * weeks);

    // Account for christmas holidays
    // if the calulated end date is after the start of holidays
    if (courseEndDate.isAfter(holStart)) {
        // if end date is after the end of holidays just add duration
        if (courseEndDate.isAfter(holEnd)) {
            courseEndDate.add(holDuration, 'days');
        } else {
            // otherwise the course would finish during the holidays. So get the difference and at it on the the end of the holidays
            const diff =
                moment.duration(courseEndDate.diff(holStart)).asDays() + 1;
            courseEndDate = holEnd.clone().add(diff, 'days');
        }
    }

    return courseEndDate.format('DD/MM/YYYY');
};

const getCourseTypeIndex = (
    courseTypes: ICourseType[],
    name: string
): number => {
    const courseType = courseTypes.find((a) => a.name.includes(name));

    if (courseType) {
        const index = courseTypes.indexOf(courseType);
        return index > -1 ? index : 0;
    }

    return 0;
};

export const appStateManager = {
    ACTION_ACCOMM_START_DATE_CHANGED,
    ACTION_ACCOMM_TYPE_CHANGED,
    ACTION_ACCOMM_WEEKS_CHANGED,
    ACTION_COURSE_CHANGED,
    ACTION_COURSE_TYPE_CHANGED,
    ACTION_DATE_CHANGED,
    ACTION_DOB_CHANGED,
    ACTION_EMAIL_CHANGED,
    ACTION_FIRST_NAME_CHANGED,
    ACTION_FAMILY_NAME_CHANGED,
    ACTION_LOCATION_CHANGED,
    ACTION_NATIONALITY_CHANGED,
    ACTION_PHONE_CHANGED,
    ACTION_SPECIAL_REQ_CHANGED,
    ACTION_GENDER_CHANGED,
    ACTION_TRANSFER_CHANGED,
    ACTION_WEEK_CHANGED,
    ACTION_GUARDIAN_NAME_CHANGED,
    ACTION_GUARDIAN_ADDRESS_CHANGED,
    ACTION_GUARDIAN_HOME_PHONE_CHANGED,
    ACTION_GUARDIAN_MOBILE_PHONE_CHANGED,
    ACTION_GUARDIAN_EMAIL_CHANGED,
    ACTION_GUARDIAN_VERIFY_EMAIL_CHANGED,
    VALIDATE_FIELDS,
    ACTION_OPTIONAL_EXTRA_CHANGED,
    INIT_DATA,
    INIT_DATA_STORAGE,
    initialState,
    reducer,
};
