import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @ts-ignore
// import { PayPalButton } from 'react-paypal-button-v2';

// material-ui
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

// Assets
import Stripe from '../../assets/images/stripe.png';

// Components
import { AppSpinner, FormSection, MUISnackbar, TermsDialog } from '../';

// State
import AppContext from '../../Contexts/AppContext';

const useStyle = makeStyles({
  checkboxSection: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.80rem',
  },
  totalCell: {
    fontWeight: 'bold',
  },
  totalRow: {
    backgroundColor: '#f8f8f8',
  },
  matRow: {
    '&:nth-child(even)': {
      backgroundColor: '#f8f8f8',
      border: 0,
    },
  },
  divider: {
    borderBottom: '1px solid #000',
    lineHeight: '0.1em',
    margin: '10px 0 20px',
    textAlign: 'center',
    width: '100%',
    '& span': {
      backgroundColor: 'rgba(191, 191, 191, 0.15)',
      padding: '10px',
    },
  },
  paySection: {
    display: 'flex',
    justifyContent: 'center',
  },
  payIcon: {
    marginLeft: '5px',
  },
  payButton: {
    marginRight: '20px',
  },
  checkbox: {
    paddingLeft: 0,
  },
  insurance: {
    display: 'flex',
    alignItems: 'center',
  },
});

declare global {
  interface Window {
    paypal: any;
  }
}

const deposit = 200;
const ccFee = 0.025;
const apiUrl = process.env.REACT_APP_API_URL || '';

const BookingSummary = () => {
  const stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);
  const paypal = window.paypal;

  const { state } = useContext<IAppContext>(AppContext);

  const preArrivalFluencyBuilderPrice = state.extras.preArrivalFluencyBuilder
    .selected
    ? state.extras.preArrivalFluencyBuilder.price
    : 0;

  const postCourseFluencyPlusPrice = state.extras.postCourseFluencyPlus.selected
    ? state.extras.postCourseFluencyPlus.price
    : 0;

  const oneToOnePrice = state.extras.oneToOne.selected
    ? state.extras.oneToOne.price
    : 0;

  const hoodieOnArrivalPrice = state.extras.hoodieOnArrival.selected
    ? state.extras.hoodieOnArrival.price
    : 0;

  const total =
    state.selectedCourseData.adminFee +
    state.selectedCourseData.price +
    state.selectedAccommData.price +
    state.selectedAccommData.accommFee +
    state.selectedTransferData.price +
    preArrivalFluencyBuilderPrice +
    postCourseFluencyPlusPrice +
    oneToOnePrice +
    hoodieOnArrivalPrice;

  console.log('TOTAL:::', total);

  const [loading, setLoading] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [insuranceAgreed, setInsuranceAgreed] = useState(false);
  const [toggleTerms, setToggleTerms] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');
  const [termsWarning, setTermsWarning] = useState(false);
  const [insuranceWarning, setInsuranceWarning] = useState(false);
  const [payOption, setPayOption] = useState(
    state.selectedCourseData.isOnline ? 'fullAmount' : 'deposit'
  );
  const [payTotal, setPayTotal] = useState(
    state.selectedCourseData.isOnline ? total : deposit
  );
  const classes = useStyle();
  const { t } = useTranslation();

  const handleCardClick = async () => {
    if (!termsAgreed) {
      setTermsWarning(true);
      return;
    }

    if (state.selectedCourseData.isJunior && !insuranceAgreed) {
      setInsuranceWarning(true);
      return;
    }

    try {
      setLoading(true);

      const result = await axios.post(`${apiUrl}stripeSession`, {
        ...state.requestObject,
        amount: Math.trunc(+(payTotal + payTotal * ccFee).toFixed(2) * 100),
        currency: 'eur',
        amount_paid: payTotal,
      });

      if (result.status === 200) {
        stripe
          .redirectToCheckout({
            sessionId: result.data.id,
          })
          .then((res: any) => console.log(res));
      } else if (result.status === 500) {
        window.location.href = '/500';
      }
    } catch (err) {
      setLoading(false);
      window.location.href = '/500';
    }
  };

  const handleCompleteBookingClick = async () => {
    try {
      setLoading(true);
      const result = await axios.post(
        `${apiUrl}academicCourseBooking`,
        state.requestObject
      );
      if (result.status === 200) {
        setRedirectPath(`/confirmation?session_id=${result.data}`);
      } else if (result.status === 500) {
        window.location.href = '/500';
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleInsuranceAgreedChanged = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInsuranceAgreed(target.checked);
  };

  const handlePaymentOptionChanged = ({ target }: any) => {
    setPayOption(target.value);
    setPayTotal(target.value === 'deposit' ? deposit : total);
  };

  const handleTermsAgreedChanged = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(target.checked);
  };

  const handleTermsClose = () => {
    setToggleTerms(false);
  };

  const handleTermsClick = (e: any) => {
    e.preventDefault();
    setToggleTerms(true);
  };

  const formatNumber = (value: number): string =>
    value.toLocaleString('en-IE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const createData = (name: string, price: number) => ({ name, price });
  // let rows = !state.selectedCourseData.isJunior
  //   ? [
  //       createData(`${t('admin_fee')}`, state.selectedCourseData.adminFee),
  //       createData(`${t('course_price')}`, state.selectedCourseData.price),
  //       createData(`${t('accomm_price')}`, state.selectedAccommData.price),
  //       createData(`${t('accomm_fee')}`, state.selectedAccommData.accommFee),
  //       createData(`${t('transfer_price')}`, state.selectedTransferData.price),
  //     ]
  //   : [
  //       createData(`${t('course_price')}`, state.selectedCourseData.price),
  //       createData(`${t('transfer_price')}`, state.selectedTransferData.price),
  //     ];

  let rows: any = [];

  if (state.selectedCourseData.isOnline) {
    // rows = state.selectedCourseData.isJunior
    //   ? [
    //       createData(`${t('admin_fee')}`, state.selectedCourseData.adminFee),
    //       createData(`${t('course_price')}`, state.selectedCourseData.price),
    //     ]
    //   : [createData(`${t('course_price')}`, state.selectedCourseData.price)];

    rows = [
      // createData(`${t('admin_fee')}`, state.selectedCourseData.adminFee),
      createData(`${t('course_price')}`, state.selectedCourseData.price),
    ];
  } else {
    rows = !state.selectedCourseData.isJunior
      ? [
          createData(`${t('admin_fee')}`, state.selectedCourseData.adminFee),
          createData(`${t('course_price')}`, state.selectedCourseData.price),
          createData(`${t('accomm_price')}`, state.selectedAccommData.price),
          createData(`${t('accomm_fee')}`, state.selectedAccommData.accommFee),
          createData(
            `${t('transfer_price')}`,
            state.selectedTransferData.price
          ),
        ]
      : [
          createData(`${t('course_price')}`, state.selectedCourseData.price),
          createData(
            `${t('transfer_price')}`,
            state.selectedTransferData.price
          ),
        ];
  }

  if (preArrivalFluencyBuilderPrice > 0) {
    rows = [
      ...rows,
      createData(
        'Optional Extra: Pre-Arrival Fluency Builder',
        state.extras.preArrivalFluencyBuilder.price
      ),
    ];
  }

  if (postCourseFluencyPlusPrice > 0) {
    rows = [
      ...rows,
      createData(
        'Optional Extra: Post Course Fluency Plus',
        state.extras.postCourseFluencyPlus.price
      ),
    ];
  }

  if (oneToOnePrice > 0) {
    rows = [
      ...rows,
      createData('Optional Extra: One-to-One', state.extras.oneToOne.price),
    ];
  }

  if (hoodieOnArrivalPrice > 0) {
    rows = [
      ...rows,
      createData(
        'Optional Extra: ICE Hoodie on Arrival',
        state.extras.hoodieOnArrival.price
      ),
    ];
  }

  // if (state.selectedCourseData.adminFee > 0) rows = [...rows, createData(`${t('admin_fee')}`, state.selectedCourseData.adminFee)];
  // if (state.selectedCourseData.price > 0) rows = [...rows, createData(`${t('course_price')}`, state.selectedCourseData.price)];
  // if (state.selectedAccommData.price > 0) rows = [...rows, createData(`${t('accomm_price')}`, state.selectedAccommData.price)];
  // if (state.selectedAccommData.accommFee > 0) rows = [...rows, createData(`${t('accomm_fee')}`, state.selectedAccommData.accommFee)];
  // if (state.selectedTransferData.price > 0) rows = [...rows, createData(`${t('transfer_price')}`, state.selectedTransferData.price)];

  const handleTermsWarningClose = () => {
    setTermsWarning(false);
  };

  const onPaypalApproveHandler = (data: any, actions: any) => {
    return actions.order.capture().then(async (details: any) => {
      try {
        const result = await axios.post(`/${apiUrl}paypalSession`, {
          ...state.requestObject,
          amount_paid: payTotal,
          session_id: data.orderID,
        });

        if (result.status === 200) {
          setRedirectPath(`/confirmation?session_id=${data.orderID}`);
        } else {
          window.location.href = '/500';
        }
      } catch (err) {
        setLoading(false);
      }
    });
  };

  const onPayPalCancelHandler = () => {
    setLoading(false);
  };

  const onPayPalClickHandler = () => {
    // @ts-ignore
    if (!document.querySelector('#terms')!.checked) {
      setTermsWarning(true);
    } else if (
      state.selectedCourseData.isJunior &&
      // @ts-ignore
      !document.querySelector('#insurance')!.checked
    ) {
      setInsuranceWarning(true);
    } else {
      setLoading(true);
    }
  };

  const onPaypalCreateOrderHandler = (data: any, actions: any) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: `${payTotal}`,
          },
        },
      ],
    });
  };

  const onPayPalErrorHandler = () => {
    setLoading(false);
  };

  const onPayPalInitHandler = (data: any, actions: any) => {
    actions.disable();

    const chkInsurance = document.querySelector('#insurance');

    document
      .querySelector('#terms')!
      .addEventListener('change', (event: any) => {
        let checked = true;

        if (chkInsurance) {
          // @ts-ignore
          checked = chkInsurance.checked;
        }

        if (event.target.checked && checked) {
          actions.enable();
        } else {
          actions.disable();
        }
      });

    if (chkInsurance) {
      chkInsurance.addEventListener('change', (event: any) => {
        // @ts-ignore
        const termsChecked = document.querySelector('#terms')!.checked;

        if (event.target.checked && termsChecked) {
          actions.enable();
        } else {
          actions.disable();
        }
      });
    }
  };

  useEffect(() => {
    let clearTermsWarning: NodeJS.Timeout;
    let clearInsuranceWarning: NodeJS.Timeout;

    if (termsWarning) {
      clearTermsWarning = setTimeout(() => {
        setTermsWarning(false);
      }, 5000);
    }

    if (insuranceWarning) {
      clearInsuranceWarning = setTimeout(() => {
        setInsuranceWarning(false);
      }, 5000);
    }
    return () => {
      clearTimeout(clearTermsWarning);
      clearTimeout(clearInsuranceWarning);
    };
  }, [termsWarning, insuranceWarning]);

  return redirectPath ? (
    <Redirect to={redirectPath} />
  ) : (
    <>
      {loading ? <AppSpinner /> : undefined}
      <FormSection title={t('booking_summary')}>
        {!state.selectedCourseData.isAcademicYear ? (
          <>
            <p>
              <Typography variant="subtitle1" component="span">
                {t('deposit')}
              </Typography>
            </p>
            <p>
              <Typography variant="subtitle1" component="span">
                {t('full_payment')}
              </Typography>
            </p>
            <p>
              <Typography variant="caption" component="span">
                {t('creditcard_fee')}
              </Typography>
            </p>
          </>
        ) : (
          <></>
        )}
        <Paper>
          <Table>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow className={classes.matRow} key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{formatNumber(row.price)}</TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.totalRow}>
                <TableCell className={classes.totalCell}>
                  {t('total')}
                </TableCell>
                <TableCell align="right" className={classes.totalCell}>
                  {formatNumber(total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <br />
        <section>
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose Payment Option</FormLabel>

            <RadioGroup
              value={payOption}
              onChange={handlePaymentOptionChanged}
              row
            >
              {!state.selectedCourseData.isOnline &&
                !state.selectedCourseData.isPartTime && (
                  <FormControlLabel
                    value="deposit"
                    control={<Radio />}
                    label="Deposit"
                  />
                )}
              <FormControlLabel
                value="fullAmount"
                control={<Radio />}
                label="Full Amount"
              />
            </RadioGroup>
          </FormControl>
        </section>
        <br />
        <section className={classes.checkboxSection}>
          <div>
            <Checkbox
              className={classes.checkbox}
              id="terms"
              onChange={handleTermsAgreedChanged}
              checked={termsAgreed}
            />
            <span>
              {`${t('terms')} `}
              <a href="./" onClick={handleTermsClick}>
                {t('terms_link')}
              </a>
              .
            </span>
          </div>
          {state.selectedCourseData.isJunior ? (
            <div className={classes.insurance}>
              <Checkbox
                className={classes.checkbox}
                id="insurance"
                checked={insuranceAgreed}
                onChange={handleInsuranceAgreedChanged}
              />
              <div>{t('insurance')}</div>
            </div>
          ) : undefined}
        </section>
        <br />
        <section className={classes.paySection}>
          {!state.selectedCourseData.isAcademicYear ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                disabled={loading}
                onClick={handleCardClick}
                style={{ height: '35px', width: 'auto' }}
                variant="contained"
              >
                {`${t('pay_with_card')} (€${(
                  payTotal +
                  payTotal * ccFee
                ).toFixed(2)})`}
              </Button>

              {/* <h5>
                <span>{t('or')}</span>
              </h5> */}
              {/* <div id="paypal-button" /> */}
              {/* {!state.selectedCourseData.isAcademicYear && (
                <PayPalButton
                  createOrder={onPaypalCreateOrderHandler}
                  onApprove={onPaypalApproveHandler}
                  onCancel={onPayPalCancelHandler}
                  onClick={onPayPalClickHandler}
                  onError={onPayPalErrorHandler}
                  onInit={onPayPalInitHandler}
                />
              )} */}
            </div>
          ) : (
            <Button
              disabled={
                !(
                  (insuranceAgreed || !state.selectedCourseData.isJunior) &&
                  termsAgreed &&
                  state.validationErrors.length === 0
                ) || loading
              }
              onClick={handleCompleteBookingClick}
              variant="contained"
            >
              Complete Booking
            </Button>
          )}
        </section>
        <section style={{ marginTop: '20px', width: '20%' }}>
          <a
            href="https://www.stripe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Stripe} alt="Stripe Logo" style={{ width: '150px' }} />
          </a>
        </section>
        <TermsDialog
          open={toggleTerms}
          onClose={handleTermsClose}
          handleCloseClick={handleTermsClose}
        />
        {termsWarning ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={termsWarning}
          >
            <MUISnackbar
              message={t('val_terms')}
              onClose={handleTermsWarningClose}
              variant="error"
            />
          </Snackbar>
        ) : (
          <span />
        )}
        {insuranceWarning ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={insuranceWarning}
          >
            <MUISnackbar message={t('val_terms')} variant="error" />
          </Snackbar>
        ) : (
          <span />
        )}
      </FormSection>
    </>
  );
};

export default BookingSummary;
