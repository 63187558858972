import React, { useContext } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

// Components
import { FormSection, MUIAutoSuggest, MUITextField, MUIDatePicker } from '../';

// State
import { appStateManager } from '../../state/app-state';
import AppContext from '../../Contexts/AppContext';

const useStyle = makeStyles({
  form: {
    // display: 'flex',
    margin: 'auto',
    width: '95%'
  },
  formInput: {
    marginBottom: '10px'
  }
});

const StudentDetails = () => {
  const spacing = 3;
  const { state, dispatch } = useContext<IAppContext>(AppContext);
  const classes = useStyle();
  const { t } = useTranslation();

  const handleDOBChanged = (e: Date | null) => {
    dispatch({ type: appStateManager.ACTION_DOB_CHANGED, payload: e });
  };

  const handleEmailChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_EMAIL_CHANGED,
      payload: target.value
    });
  };

  const handleFirstNameChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_FIRST_NAME_CHANGED,
      payload: target.value
    });
  };

  const handleFamilyNameChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_FAMILY_NAME_CHANGED,
      payload: target.value
    });
  };

  const handleNationalityChanged = (value: string) => {
    dispatch({
      type: appStateManager.ACTION_NATIONALITY_CHANGED,
      payload: value
    });
  };

  const handlePhoneChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_PHONE_CHANGED,
      payload: target.value
    });
  };

  const handleGenderChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GENDER_CHANGED,
      payload: target.value
    });
  };

  const handleGuardianNameChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GUARDIAN_NAME_CHANGED,
      payload: target.value
    });
  };

  const handleGuardianAddressChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GUARDIAN_ADDRESS_CHANGED,
      payload: target.value
    });
  };

  const handleGuardianHomePhoneChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GUARDIAN_HOME_PHONE_CHANGED,
      payload: target.value
    });
  };

  const handleGuardianMobilePhoneChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GUARDIAN_MOBILE_PHONE_CHANGED,
      payload: target.value
    });
  };

  const handleGuardianEmailChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GUARDIAN_EMAIL_CHANGED,
      payload: target.value
    });
  };

  const handleGuardianVerifyEmailChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_GUARDIAN_VERIFY_EMAIL_CHANGED,
      payload: target.value
    });
  };

  return (
    <FormSection title={t('student_details')}>
      <div className={classes.form}>
        <Grid container spacing={spacing} style={{ marginBottom: '-20px' }}>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('gender')}</FormLabel>
              <RadioGroup
                value={state.studentData.gender}
                onChange={handleGenderChanged}
                row
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={t('female')}
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={t('male')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <MUITextField
              error={!state.validation.firstName.isValid}
              errorText={state.validation.firstName.errorText}
              label={t('first_name')}
              onChange={handleFirstNameChanged}
              value={state.studentData.firstName}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <MUITextField
              error={!state.validation.familyName.isValid}
              errorText={state.validation.familyName.errorText}
              label={t('family_name')}
              onChange={handleFamilyNameChanged}
              required
              value={state.studentData.familyName}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <MUIDatePicker
              error={!state.validation.dob.isValid}
              errorText={state.validation.dob.errorText}
              disableFuture
              label={t('dob')}
              onChange={handleDOBChanged}
              placeholder="DD/MM/YYYY"
              required
              value={state.studentData.dob}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                disabled: true
              }}
            // views={['year', 'month', 'date']}
            />
          </Grid>
          <Grid item xs={6}>
            <MUIAutoSuggest
              error={!state.validation.nationality.isValid}
              errorText={state.validation.nationality.errorText}
              onChange={handleNationalityChanged}
              label={t('nationality')}
              placeholder={t('type_to_search')}
              required
              suggestions={state.nationalities.map(n => ({
                label: n.nationality
              }))}
              value={state.studentData.nationality}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={spacing}>
          <Grid item xs={6}>
            <MUITextField
              error={!state.validation.email.isValid}
              errorText={state.validation.email.errorText}
              label={t('email')}
              onChange={handleEmailChanged}
              required={!state.selectedCourseData.isJunior}
              value={state.studentData.email}
            />
          </Grid>
          <Grid item xs={6}>
            <MUITextField
              error={!state.validation.phone.isValid}
              errorText={state.validation.phone.errorText}
              label={t('phone')}
              onChange={handlePhoneChanged}
              required={!state.selectedCourseData.isJunior}
              value={state.studentData.phone}
            />
          </Grid>
        </Grid>
      </div>
      {state.selectedCourseData.isJunior ? (
        <>
          <div style={{ marginTop: '50px' }} />
          <h3>{t('parent_details')}</h3>
          <div className={classes.form}>
            <Grid container spacing={spacing}>
              <Grid item xs={6}>
                <MUITextField
                  error={!state.validation.guardianName.isValid}
                  errorText={state.validation.guardianName.errorText}
                  label={t('name')}
                  onChange={handleGuardianNameChanged}
                  required
                  value={state.studentData.guardianName}
                />
              </Grid>
              {/* <Grid item xs={6}></Grid> */}
              <Grid item xs={6}>
                <MUITextField
                  error={!state.validation.guardianAddress.isValid}
                  errorText={state.validation.guardianAddress.errorText}
                  label={t('address')}
                  multiline
                  onChange={handleGuardianAddressChanged}
                  placeholder={`1 Main Street\nMalahide\nCo. Dublin\nIreland`}
                  required
                  rows={5}
                  value={state.studentData.guardianAddress}
                />
              </Grid>
              {/* <Grid item xs={6}></Grid> */}
              <Grid item xs={6}>
                <MUITextField
                  label={t('home_phone')}
                  onChange={handleGuardianHomePhoneChanged}
                  value={state.studentData.guardianHomePhone}
                />
              </Grid>
              <Grid item xs={6}>
                <MUITextField
                  error={!state.validation.guardianMobilePhone.isValid}
                  errorText={state.validation.guardianMobilePhone.errorText}
                  label={t('mobile')}
                  onChange={handleGuardianMobilePhoneChanged}
                  required
                  value={state.studentData.guardianMobilePhone}
                />
              </Grid>
              <Grid item xs={6}>
                <MUITextField
                  error={!state.validation.guardianEmail.isValid}
                  errorText={state.validation.guardianEmail.errorText}
                  label={t('email')}
                  onChange={handleGuardianEmailChanged}
                  required
                  value={state.studentData.guardianEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <MUITextField
                  error={!state.validation.guardianVerifyEmail.isValid}
                  errorText={state.validation.guardianVerifyEmail.errorText}
                  label={t('verify_email')}
                  onChange={handleGuardianVerifyEmailChanged}
                  required
                  value={state.studentData.guardianVerifyEmail}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
          <div />
        )}
    </FormSection>
  );
};

export default StudentDetails;
