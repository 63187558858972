import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

// Components
import { MUITextField, MUISelect } from '../components';

// Context
import AppContext from '../Contexts/AppContext';

// State
import { appStateManager } from '../state/app-state';

const size = 768;

const useStyle = makeStyles((theme: any) => ({
  body: {
    padding: '10px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 10px 0 20px',
    backgroundColor: '	rgb(240,240,240)',
    borderRadius: '5px',
    boxShadow: '0 2px 6px 0 hsla(0, 0%, 0%, 0.2)',
    overflow: 'visible',
    margin: 'auto',
    width: '85%',
    [theme.breakpoints.down(size)]: {
      flexDirection: 'column',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(size)]: {
      margin: '20px 0',
    },
  },
  item: {
    marginRight: '20px',
  },
  price: {
    width: '100px',
    [theme.breakpoints.down(size)]: {
      width: '100%',
    },
  },
  button: {
    [theme.breakpoints.down(size)]: {
      width: '100%',
    },
  },
}));

const Reservations = () => {
  const { state, dispatch } = useContext<IAppContext>(AppContext);
  const classes = useStyle();

  const handleCourseTypeChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_COURSE_TYPE_CHANGED,
      payload: +target.value,
    });
  };

  const handleLocationChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_LOCATION_CHANGED,
      payload: +target.value,
    });
  };

  const handleCourseChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_COURSE_CHANGED,
      payload: +target.value,
    });
  };

  const handleWeeksChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_WEEK_CHANGED,
      payload: +target.value,
    });
  };
  const handleBookCourseClick = () => {
    sessionStorage.setItem(
      'ice_bookings',
      JSON.stringify(state.selectedCourseData)
    );
    window.top.location.href = process.env.REACT_APP_URL || '';
  };

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <div style={{ marginRight: '10px' }}>
          <MUISelect
            dataSource={state.dataSources.courseTypes}
            displayExpr="name"
            label="Course Type"
            onChange={handleCourseTypeChanged}
            valueExpr="courseTypeId"
            // variant="outlined"
            value={state.selectedCourseData.courseType}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <MUISelect
            dataSource={state.dataSources.locations}
            displayExpr="name"
            label="Location"
            onChange={handleLocationChanged}
            value={state.selectedCourseData.location}
            valueExpr="locationId"
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <MUISelect
            dataSource={state.dataSources.courses}
            displayExpr="name"
            label="Course"
            onChange={handleCourseChanged}
            value={state.selectedCourseData.course}
            valueExpr="courseId"
          ></MUISelect>
        </div>
        <div style={{ marginRight: '10px' }}>
          <MUISelect
            dataSource={state.dataSources.courseWeeks}
            displayExpr="description"
            label="Duration"
            onChange={handleWeeksChanged}
            value={state.selectedCourseData.week}
            valueExpr="week"
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <MUITextField
            className={classes.price}
            label="Price"
            value={state.selectedCourseData.price.toLocaleString('en-IE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            // style={{ width: '100px' }}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handleBookCourseClick}
          >
            Book Course
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Reservations;
