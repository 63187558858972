import React from 'react';
import { useTranslation } from 'react-i18next';

import FlagDE from '../../assets/images/flags/DE.png';
import FlagES from '../../assets/images/flags/ES.png';
import FlagFR from '../../assets/images/flags/FR.png';
import FlagGB from '../../assets/images/flags/GB.png';
import FlagIT from '../../assets/images/flags/IT.png';
import FlagRU from '../../assets/images/flags/RU.png';

// Style
import classes from './AppTranslation.module.scss';

const AppTranslation = () => {
  const { i18n } = useTranslation();

  const onClickHandler = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <section style={{ float: 'right' }}>
      <img
        alt="English Language"
        className={classes.flag}
        src={FlagGB}
        title="English"
        onClick={() => onClickHandler('en')}
      />
      <img
        alt="French Language"
        className={classes.flag}
        src={FlagFR}
        title="Français"
        onClick={() => onClickHandler('fr')}
      />
      <img
        alt="German Language"
        className={classes.flag}
        src={FlagDE}
        title="Deutsche"
        onClick={() => onClickHandler('de')}
      />
      <img
        alt="Italian Language"
        className={classes.flag}
        src={FlagIT}
        title="Italiano"
        onClick={() => onClickHandler('it')}
      />
      <img
        alt="Russian Language"
        className={classes.flag}
        src={FlagRU}
        title="русский"
        onClick={() => onClickHandler('ru')}
      />
      <img
        alt="Spanish Language"
        className={classes.flag}
        src={FlagES}
        title="Español"
        onClick={() => onClickHandler('es')}
      />
    </section>
  );
};

export default AppTranslation;
