import React from 'react';
import { makeStyles } from '@material-ui/styles';

import Logo from '../assets/images/logo.png';

const useStyle = makeStyles({
  code: {
    fontSize: 100
  },
  divider: {
    margin: '20px 0'
  }
});

const Code500 = () => {
  const classes = useStyle();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div className={classes.divider} />
      <img src={Logo} alt="Logo" />
      <div className={classes.divider} />
      <span className={classes.code}>500</span>
      <p>We're sorry, but something has gone wrong.</p>
      <p>
        We are working to fix this issue. Please come back and try again later.
      </p>
    </div>
  );
};

export default Code500;
