import React, { Suspense, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { Route, Switch } from 'react-router-dom';

// Components
import Home from '../Home/Home';
import Reservations from '../Reservations/Reservations';
import SuccessPage from '../SuccessPage/SuccessPage';
import Code400Page from '../StatusCodePage/Code400';
import Code500Page from '../StatusCodePage/Code500';

// State
import AppContext from '../Contexts/AppContext';
import { appStateManager } from '../state/app-state';

// Utils
import { constants } from '../utils';
import { FilterMode } from '../utils/enums';

// Styles
const theme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: '#db3131',
                '&$error': {
                    color: '#db3131',
                },
            },
        },
    },
    palette: {
        primary: {
            light: '#60ad5e',
            main: '#2e7d32',
            dark: '#005005',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffd95b',
            main: '#ffa726',
            dark: '#c77800',
            contrastText: '#000000',
        },
    },
});

// interface IAppProps extends RouteComponentProps {}

const apiUrl = process.env.REACT_APP_API_URL || '';

const App: React.FC<RouteComponentProps> = ({ location }) => {
    let filterMode: FilterMode = FilterMode.All;

    if (location.pathname.indexOf('/adult') > -1) {
        filterMode = FilterMode.Adult;
    } else if (location.pathname.indexOf('/junioronline') > -1) {
        filterMode = FilterMode.JuniorOnline;
    } else if (location.pathname.indexOf('/junior') > -1) {
        filterMode = FilterMode.Junior;
    } else if (location.pathname.indexOf('group') > -1) {
        filterMode = FilterMode.Group;
    } else if (location.pathname.indexOf('individual') > -1) {
        filterMode = FilterMode.IndividualOnline;
    } else if (location.pathname.indexOf('kidsvirtualcamps') > -1) {
        filterMode = FilterMode.KidsVirtualCamps;
    } else if (location.pathname.indexOf('teenvirtualcamps') > -1) {
        filterMode = FilterMode.TeenVirtualCamps;
    }

    const [state, dispatch] = React.useReducer(
        appStateManager.reducer,
        appStateManager.initialState
    );

    useEffect(() => {
        const fetchAppData = async () => {
            const result = await axios.get(`${apiUrl}appdata`);
            if (result) {
                const storage = sessionStorage.getItem(constants.STORAGE_KEY);
                if (storage) {
                    const data = JSON.parse(storage);
                    dispatch({
                        type: appStateManager.INIT_DATA_STORAGE,
                        payload: { storageData: data, appData: result.data },
                    });
                    sessionStorage.removeItem(constants.STORAGE_KEY);
                } else {
                    console.log('Reached the useeffect', filterMode);
                    dispatch({
                        type: appStateManager.INIT_DATA,
                        payload: { ...result.data, filterMode },
                    });
                }
            }
        };

        fetchAppData();
    }, [filterMode]);

    return state.appInitialised ? (
        <AppContext.Provider value={{ state, dispatch }}>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route path="/" component={Home} exact></Route>
                        <Route
                            path="/reservations"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/adult"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/junior"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/group"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/individual"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/junioronline"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/kidsvirtualcamps"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route
                            path="/reservations/teenvirtualcamps"
                            component={Reservations}
                            exact
                        ></Route>
                        <Route path="/500" component={Code500Page} exact />
                        <Route
                            path="/confirmation"
                            component={SuccessPage}
                            exact
                        ></Route>
                        <Route component={Code400Page} />
                    </Switch>
                </Suspense>
            </ThemeProvider>
        </AppContext.Provider>
    ) : (
        <div></div>
    );
};

export default withRouter(App);
