import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';

interface ITermsDialogProps extends DialogProps {
    handleCloseClick: () => void;
}

const TermsDialog: React.FC<ITermsDialogProps> = ({
    handleCloseClick,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <Dialog {...rest} fullWidth maxWidth="lg">
            <DialogContent style={{ textAlign: 'justify' }}>
                <h2>{t('tc_TermsAndConditions')}</h2>
                {/* <ol>
          <li>{t('tc_1')}</li>
          <li>{t('tc_2')}</li>
          <li>{t('tc_3')}</li>
          <li>{t('tc_4')}</li>
          <li>{t('tc_5')}</li>
          <li>{t('tc_6')}</li>
          <li>{t('tc_7')}</li>
          <li>{t('tc_8')}</li>
          <li>{t('tc_9')}</li>
          <li>{t('tc_10')}</li>
          <li>{t('tc_11')}</li>
          <ul>
            <li>{t('tc_11_1')}</li>
            <li>{t('tc_11_2')}</li>
            <li>{t('tc_11_3')}</li>
            <li>{t('tc_11_4')}</li>
            <li>{t('tc_11_5')}</li>
            <li>{t('tc_11_6')}</li>
          </ul>
          <li>{t('tc_12')}</li>
          <li>{t('tc_13')}</li>
          <li>{t('tc_14')}</li>
        </ol> */}
                <ol>
                    <li>
                        Our <b>'Book with Confidence Guarantee'</b> allows
                        individuals and groups travelling to Ireland, to cancel
                        free of charge until 7 days before arrival. If you need
                        to cancel within 7 days of arrival we will offer a free
                        postponement of course which will be valid until the end
                        of 2025, no refunds will be given for stays cancelled
                        within 7 days of arrival.
                    </li>
                    <li>
                        Transfers: Flight number, date and time of arrival must
                        be advised to the Irish College of English as soon as
                        possible to enable us to arrange your collection at the
                        airport. Where flights are delayed/cancelled, the
                        college should be informed immediately to allow us to
                        reschedule transfers, where we are unable to reschedule
                        transfers due to lack of notice, students will be
                        required to arrange and pay for taxi transport to their
                        host family.
                    </li>
                    <li>
                        We advise all clients to take out full Personal &
                        Medical Insurance including Covid-19 cover prior to
                        arrival as ICE accepts no responsibility in the event of
                        illness, theft, accident, damages or loss of personal
                        effects. All clients from E.U. countries should bring a
                        European Health Insurance Card. Non EU students must
                        take out private medical insurance. Students/Parents are
                        responsible for all medical & related costs incurred
                        during their stay. Group/Individual Waivers must be
                        signed pre arrival.
                    </li>
                    <li>
                        We do not accept responsibility for costs incurred due
                        to flight delays i.e. accommodation etc.
                    </li>
                    <li>
                        ICE reserves the right to expel students who do not
                        abide by our school policies or who take part in any
                        activities that ICE deems unacceptable. In this
                        situation, agents/parents are responsible for arranging
                        accommodation / rescheduling flight home etc. No refunds
                        will be given.
                    </li>
                    <li>
                        Registration will be confirmed for each student only
                        upon receipt of completed registration forms and full
                        payment of fees.
                    </li>
                    <li>
                        Where damage/theft of property of the host family or to
                        any other property is caused by a student, this student
                        (where the student is a minor, the student's parents)
                        will be responsible for the cost of any such damage and
                        payment should be immediate, where credit card details
                        are in our system, this payment method will be used.
                    </li>
                    <li>
                        ICE reserves the right to cancel arrangements if the
                        booking conditions are not met.
                    </li>
                    <li>
                        ICE reserves the right to cancel programmes where there
                        are insufficient numbers or where for any reason the
                        programme cannot be run. Where a programme is cancelled,
                        we will move students' bookings to an alternative
                        programme for the same dates. Should a student wish to
                        cancel a booking in these circumstances our normal
                        refund policy will apply. Should no alternative
                        programme be available, ICE will issue a refund / course
                        credit, no other compensation will be paid.
                    </li>
                    <li>
                        ICE must be advised of any health problems or specific
                        dietary requirements at the time of booking and Covid-19
                        status must be submitted prior to arrival.
                    </li>
                    <li>
                        If for any reason deemed acceptable to ICE a student can
                        no longer be accommodated in host family accommodation
                        or our residences, it is the agent's/parents'
                        responsibility to find suitable accommodation for the
                        student. ICE will refund the agent/parents any unused
                        accommodation fees. Examples of such situations may be –
                        unacceptable student behaviour, a medical problem which
                        the family is unable to adapt to, a contagious disease
                        etc.
                    </li>
                    <li>
                        There is no refund for students who change from
                        Intensive to other courses of lesser hours. There is no
                        refund of fees for days missed during the course, for
                        late arrival or early departure or for public holidays.
                    </li>
                    <li>
                        Visas – if a student does not acquire their visa, we
                        will refund all fees paid, except for 200 euro which we
                        keep as an administration fee. In these circumstances, a
                        statement from the relevant consultant/embassy that no
                        visa was issued is required. Once visa students have
                        used our course details to enter Ireland, classes must
                        be attended and all fees are non-refundable.
                    </li>
                    <li>
                        All student under the age of 18 years of age taking an
                        online course must have adult supervision at all times
                        to ensure their safety online.
                    </li>
                    <li>
                        In the case of a Force Majeure event, the above terms
                        and conditions may not be possible for us to fulfil
                        however we will endeavour to make reasonable efforts to
                        resume our normal operations, terms and conditions.
                        Courses may be postponed, in this case a full credit or
                        refund will be applied and the course can be reorganised
                        for a later date. No other compensation will apply.
                    </li>
                </ol>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleCloseClick}>
                    {t('button_close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsDialog;
