import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/styles';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers/';

interface IMUIDatePickerProps extends KeyboardDatePickerProps {
  error?: boolean;
  errorText?: string;
}

const useStyle = makeStyles({
  formControl: {
    marginBottom: '0 !important',
    width: '100%'
  }
});

const MUIDatePicker: React.FC<IMUIDatePickerProps> = ({
  error,
  errorText,
  ...rest
}) => {
  const classes = useStyle();
  return (
    <FormControl className={classes.formControl} error={error}>
      <KeyboardDatePicker
        {...rest}
        error={error}
        format={'dd/MM/yyyy'}
        fullWidth
      />
      {error ? <FormHelperText>{errorText}</FormHelperText> : undefined}
    </FormControl>
  );
};

export default MUIDatePicker;
