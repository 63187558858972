import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';

// Components
import {
    Accommodation,
    AppTranslation,
    JuniorExtras,
    BookingSummary,
    Courses,
    Payment,
    StudentDetails,
    Virtual,
} from '../components';

// i18n
import { useTranslation } from 'react-i18next';

// Images
import Logo from '../assets/images/logo.png';

// State
import AppContext from '../Contexts/AppContext';
import { appStateManager } from '../state/app-state';

const size = 768;

const useStyle = makeStyles((theme: any) => ({
    container: {
        margin: 'auto',
        width: '50%',
        [theme.breakpoints.down(size)]: {
            margin: 'auto auto',
            width: 'auto',
        },

        // display: 'flex',
        // alignItems: 'center'
    },
    // button: {
    //   margin: '0 40px'
    // },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0',
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 10px',
    },
    img: {
        margin: '20px 0 20px 0',
        [theme.breakpoints.down(size)]: {
            width: '100%',
        },
    },
    testBanner: {
        fontWeight: 'bold',
        color: 'red',
        display: 'flex',
        justifyContent: 'center',
        border: '1px solid black',
        padding: '10px',
    },
}));

const Home: React.FC = () => {
    const { state, dispatch } = useContext<IAppContext>(AppContext);
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyle();
    const { t } = useTranslation();

    const steps = [
        { name: t('stepper_courses'), icon: undefined },
        {
            name: t('stepper_accommodation'),
            icon: undefined,
        },
        { name: t('stepper_details'), icon: undefined },
        { name: 'Optional Extras', icon: undefined },
        { name: t('stepper_summary'), icon: undefined },
    ];

    // Yup.addMethod(Yup.string, 'juniorRequired', function (message, value) {
    //   return Yup.string().test('juniorRequired_test', message, () => {
    //     return !(state.selectedCourseData.isJunior && !value);
    //   });
    // });

    // Yup.addMethod(Yup.string, 'adultRequired', function (message, value) {
    //   return Yup.string().test('adultRequired_test', message, (v) => {
    //     return !(!state.selectedCourseData.isJunior && !value);
    //   });
    // });

    // Yup.addMethod(Yup.string, 'juniorEmailMatch', function (email, verifyEmail) {
    //   return Yup.string().test(
    //     'junior_email_match_test',
    //     t('val_email_match'),
    //     () => !(email !== verifyEmail)
    //   );
    // });

    Yup.addMethod(Yup.string, 'juniorRequired', function (message, value) {
        // @ts-ignore
        return this.test('juniorRequired_test', message, (v) => {
            return !(state.selectedCourseData.isJunior && !value);
        });
    });

    Yup.addMethod(Yup.string, 'adultRequired', function (message, value) {
        // @ts-ignore
        return this.test('adultRequired_test', message, (v) => {
            return !(!state.selectedCourseData.isJunior && !value);
        });
    });

    Yup.addMethod(
        Yup.string,
        'juniorEmailMatch',
        function (email, verifyEmail) {
            // @ts-ignore
            return this.test(
                'junior_email_match_test',
                t('val_email_match'),
                () => !(email !== verifyEmail)
            );
        }
    );

    const schema = Yup.object().shape({
        dob: Yup.date().required(t('val_dob')),
        email: Yup.string()
            .email(t('val_email_invalid'))
            // @ts-ignore
            .adultRequired(t('val_email'), state.studentData.email),
        familyName: Yup.string().required(t('val_family_name')),
        firstName: Yup.string().required(t('val_first_name')),
        nationality: Yup.string().required(t('val_nationality')),
        // phone: Yup.string().required('Please enter a Phone Number'),
        phone: Yup.string()
            // @ts-ignore
            .adultRequired(t('val_phone'), state.studentData.phone),
        title: Yup.string(),
        guardianName: Yup.string()
            // @ts-ignore
            .juniorRequired(t('val_name'), state.studentData.guardianName),
        guardianAddress: Yup.string()
            // @ts-ignore
            .juniorRequired(t('val_email'), state.studentData.guardianAddress),
        guardianMobilePhone: Yup.string()
            // @ts-ignore
            .juniorRequired(
                t('val_mobile'),
                state.studentData.guardianMobilePhone
            ),
        guardianEmail: Yup.string()
            // @ts-ignore
            .juniorEmailMatch(
                state.studentData.guardianEmail,
                state.studentData.guardianVerifyEmail
            )
            // @ts-ignore
            .juniorRequired(t('val_email'), state.studentData.guardianEmail)
            .email(t('val_email_invalid')),
        guardianVerifyEmail: Yup.string()
            // @ts-ignore
            .juniorEmailMatch(
                state.studentData.guardianEmail,
                state.studentData.guardianVerifyEmail
            )
            // @ts-ignore
            .juniorRequired(
                t('val_email'),
                state.studentData.guardianVerifyEmail
            )
            .email(t('val_email_invalid')),
    });

    const accommSchema = Yup.object().shape({
        accommStartDate: Yup.string().test(
            'accomm-test',
            t('val_accomm_date'),
            () => {
                if (
                    (state.selectedAccommData.type > 1 &&
                        state.selectedAccommData.startDate) === null
                ) {
                    return false;
                }

                return true;
            }
        ),
    });

    const getStepContent = (stepIndex: number): React.ReactNode => {
        switch (stepIndex) {
            case 0:
                return <Courses />;
            case 1:
                return state.selectedCourseData.isVirtual ? (
                    <Virtual />
                ) : (
                    <Accommodation />
                );
            case 2:
                return <StudentDetails />;
            case 3:
                return <JuniorExtras />;
            case 4:
                return <BookingSummary />;
            case 5:
                return <Payment />;
            default:
                return <div></div>;
        }
    };

    const handleBack = () => {
        // setActiveStep((prev) => prev - 1);

        setActiveStep((prev) => {
            if (!state.selectedCourseData.allowAccomm && prev === 2) {
                return 0;
            }

            // If not a junior course then skip "Extras"
            if (!state.selectedCourseData.isJunior && prev === 4) {
                return 2;
            }

            return prev - 1;
        });
    };

    const handleNext = async () => {
        try {
            if (activeStep !== steps.length - 1) {
                if (activeStep === 1) {
                    await accommSchema.validate(state.selectedAccommData, {
                        abortEarly: false,
                    });
                }
                if (activeStep === 2) {
                    await schema.validate(state.studentData, {
                        abortEarly: false,
                    });
                }

                // setActiveStep((prev) => prev + 1);

                setActiveStep((prev) => {
                    if (!state.selectedCourseData.allowAccomm && prev === 0) {
                        return 2;
                    }

                    if (prev === 2 && !state.selectedCourseData.isJunior) {
                        return 4;
                    }

                    return prev + 1;
                });
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                dispatch({
                    type: appStateManager.VALIDATE_FIELDS,
                    payload: err.inner.map((e: any) => ({
                        field: e.path,
                        errorText: e.errors[0],
                    })),
                });
            } else {
                console.log(err);
            }
        }
    };

    return (
        <>
            <div className={classes.container}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AppTranslation />
                </div>
                <div className={classes.imgContainer}>
                    <img className={classes.img} src={Logo} alt="Logo" />
                </div>
                <br />

                {process.env.REACT_APP_NODE_ENV !== 'production' ? (
                    <div className={classes.testBanner}>
                        <span>** TEST VERSION **</span>
                    </div>
                ) : undefined}
                <Stepper alternativeLabel activeStep={activeStep}>
                    {steps.map((step) => (
                        <Step key={step.name}>
                            <StepLabel StepIconComponent={step.icon}>
                                {step.name}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? undefined : (
                        <section>
                            <>
                                <div>{getStepContent(activeStep)}</div>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        disabled={activeStep === 0}
                                        color="primary"
                                        onClick={handleBack}
                                        variant="contained"
                                    >
                                        <NavigateBeforeIcon />
                                        {t('button_back')}
                                    </Button>
                                    {process.env.REACT_APP_NODE_ENV ===
                                    'development' ? (
                                        <Button
                                            onClick={() => console.log(state)}
                                        >
                                            State
                                        </Button>
                                    ) : undefined}
                                    {activeStep < steps.length - 1 ? (
                                        <Button
                                            color="primary"
                                            disabled={
                                                activeStep === steps.length - 1
                                            }
                                            onClick={handleNext}
                                            variant="contained"
                                        >
                                            {t('button_next')}
                                            <NavigateNextIcon />
                                        </Button>
                                    ) : undefined}
                                </div>
                            </>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
