import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select, { SelectProps } from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';

interface IMUISelectProps extends SelectProps {
  dataSource: any[];
  displayExpr: string;
  label: string;
  valueExpr: string;
  errorText?: string;
}

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    overflow: 'visible !important'
  }
});

const MUISelect: React.FC<IMUISelectProps> = ({
  dataSource = [],
  displayExpr,
  errorText,
  label,
  name,
  value,
  valueExpr,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select">{label}</InputLabel>
      <Select {...rest} native value={value || ''}>
        {dataSource.map(item => (
          // <MenuItem key={item[valueExpr]} value={item[valueExpr]}>
          //   {item[displayExpr]}
          // </MenuItem>
          <option key={item[valueExpr]} value={item[valueExpr]}>
            {item[displayExpr]}
          </option>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default MUISelect;
