import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

// Components
import { FormSection, MUIDatePicker, MUITextField, MUISelect } from '..';

// State
import AppContext from '../../Contexts/AppContext';
import { appStateManager } from '../../state/app-state';
import { Tooltip } from '@material-ui/core';

// Style
const useStyle = makeStyles({
  form: {
    margin: 'auto',
    width: '95%',
  },
  formInput: {},
  subTitle: {
    fontWeight: 'bold',
    marginRight: '20px',
  },
  accommIncluded: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  included: {
    background: '#fff',
    boxShadow: '0 2px 6px 0 hsla(0, 0%, 0%, 0.2)',
    padding: '15px',
    fontWeight: 'bold',
  },
  header: {
    display: 'inline-block',
    fontSize: '1.25em',
    marginBottom: '0.75em',
  },
  bold: {
    fontWeight: 'bold',
  },
});

const Accommodation = () => {
  const spacing = 3;
  const { state, dispatch } = useContext<IAppContext>(AppContext);
  const classes = useStyle();
  const { t } = useTranslation();

  const handleAccommStartDateChanged = (value: any) => {
    dispatch({
      type: appStateManager.ACTION_ACCOMM_START_DATE_CHANGED,
      payload: value,
    });
  };

  const handleAccommTypeChanged = ({ target }: any) => {
    console.log('HERE!');
    dispatch({
      type: appStateManager.ACTION_ACCOMM_TYPE_CHANGED,
      payload: +target.value,
    });
  };

  const handleAccommWeeksChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_ACCOMM_WEEKS_CHANGED,
      payload: +target.value,
    });
  };

  const handleSpecialRequirementsChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_SPECIAL_REQ_CHANGED,
      payload: target.value,
    });
  };

  const handleTransferChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_TRANSFER_CHANGED,
      payload: +target.value,
    });
  };

  return (
    <FormSection
      title=""
      total={state.selectedAccommData.price + state.selectedTransferData.price}
    >
      {state.selectedCourseData.isJunior ? (
        <div>
          <span className={classes.header}>
            {`${t('accomm_junior_welcome')} ${
              state.selectedCourseData.locationDesc
            }`}
          </span>
          <div className={classes.accommIncluded}>
            <span className={classes.subTitle}>
              {t('accomm_full_board_accomm')}
            </span>
            <div className={classes.included}>{t('accomm_included')}</div>
          </div>

          <Tooltip title={<><p>Parents/guardians must inform the Irish College of English of any learning difficulties, additional needs, medical conditions or dietary needs etc.</p><p>This information is crucial for assessing whether the camp can adequately support a child. For more information regarding our policy on admission and care for students with additional needs and medical conditions, see our QA manual or www.iceireland.com/supports.</p><p>We will try to facilitate any special requests/dietary needs/medical conditions/additional needs. However, we cannot guarantee this and in some circumstances, we may be unable to accept the booking as per our admissions policy. Additional fees may apply for certain diets, please contact us for more information.</p></>}>
            <MUITextField
              label={t('accomm_special_requirements')}
              onChange={handleSpecialRequirementsChanged}
              style={{ margin: '15px 0' }}
              value={state.selectedAccommData.specialRequirements}
            />
          </Tooltip>

          <p className={classes.bold}>{t('accomm_accommodation_dates')}</p>
          {state.selectedCourseData.isResidential ? (
            <p>{t('accomm_non_residential_1')}</p>
          ) : (
            <p>{t('accomm_residential_1')}</p>
          )}
          <p className={classes.bold}>{t('accomm_booking_flights')}</p>
          <p>{t('accomm_await_email')}</p>
          <h4>{t('accomm_airport_transfers')}</h4>
          <p>{t('accomm_transfer_service_junior')}</p>
          {state.selectedCourseData.isResidential ? (
            <section>
              <p>{t('accomm_residential_2')}</p>
              <p>{t('accomm_residential_2_1')}</p>
              <p>{t('accomm_residential_2_2')}</p>
            </section>
          ) : (
            <></>
          )}
          <MUISelect
            dataSource={state.dataSources.transfers}
            displayExpr="name"
            label={`${t('accomm_airport_transfers')}`}
            onChange={handleTransferChanged}
            value={state.selectedTransferData.transfer}
            valueExpr="id"
          ></MUISelect>
        </div>
      ) : (
        <div className={classes.form}>
          <h4>{t('accomm_airport_transfers')}</h4>
          <p>{t('accomm_transfer_adult_adult')}</p>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <MUISelect
                dataSource={state.dataSources.transfers}
                displayExpr="name"
                label={t('accomm_airport_transfers')}
                onChange={handleTransferChanged}
                value={state.selectedTransferData.transfer}
                valueExpr="id"
              ></MUISelect>
            </Grid>
          </Grid>
          <h4>{t('stepper_accommodation')}</h4>
          <p>{t('accomm_course_start_info')}</p>
          {/* <p style={{ color: 'red' }}>
            Please note we are fully booked for host family accommodation until
            August 27
            <br />
            If you are booking a course between now and August 27 please opt for
            accommodation type Not Required
          </p> */}
          <Grid container spacing={spacing}>
            <Grid item style={{ paddingBottom: '0px' }} xs={12}>
              <MUISelect
                dataSource={state.dataSources.accommTypes}
                displayExpr="name"
                label={t('accomm_type')}
                onChange={handleAccommTypeChanged}
                value={state.selectedAccommData.type}
                valueExpr="id"
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={spacing}>
            <Grid item style={{ paddingBottom: '0px' }} xs={12}>
              <MUISelect
                dataSource={state.dataSources.accommWeeks}
                disabled={state.selectedAccommData.type === 1}
                displayExpr="week"
                label={t('courses_weeks_alt')}
                onChange={handleAccommWeeksChanged}
                value={state.selectedAccommData.week}
                valueExpr="week"
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <MUIDatePicker
                error={!state.validation.accommStartDate.isValid}
                errorText={state.validation.accommStartDate.errorText}
                disablePast
                disabled={state.selectedAccommData.type === 1}
                label={t('courses_start_date')}
                onChange={handleAccommStartDateChanged}
                value={state.selectedAccommData.startDate}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={spacing}>
            <Grid item xs={12}>              
              <MUITextField
                disabled={state.selectedAccommData.type === 1}
                onChange={handleSpecialRequirementsChanged}
                label={t('accomm_special_requirements')}
                value={state.selectedAccommData.specialRequirements}
              />              
            </Grid>
          </Grid>
        </div>
      )}
    </FormSection>
  );
};

export default Accommodation;
