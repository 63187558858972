import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';

// Components
import { FormSection, MUISelect } from '../';

// State
import AppContext from '../../Contexts/AppContext';
import { appStateManager } from '../../state/app-state';

const useStyle = makeStyles({
  form: {
    margin: 'auto',
    width: '85%'
  }
});

const AirportTransfers = () => {
  // Hooks
  const { state, dispatch } = useContext<IAppContext>(AppContext);
  const classes = useStyle();

  const handleTransferChanged = ({ target }: any) => {
    dispatch({
      type: appStateManager.ACTION_TRANSFER_CHANGED,
      payload: target.value
    });
  };

  return (
    <FormSection
      title="Airport Transfers"
      total={state.selectedTransferData.price}
    >
      <p>
        For Junior Summer Camp Students we are happy to organise professional
        airport transfers by a member of our staff to / from host families at a
        rate of 50 euro per transfer.
      </p>
      <p>
        We advise adult students to take a taxi to host families as we are less
        than 10 minutes from Dublin Airport (taxi cost approx. €20).
      </p>
      <div className={classes.form}>
        <MUISelect
          dataSource={state.dataSources.transfers}
          displayExpr="name"
          label="Airport Transfer"
          onChange={handleTransferChanged}
          value={state.selectedTransferData.transfer}
          valueExpr="id"
        />
      </div>
    </FormSection>
  );
};

export default AirportTransfers;
