import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import DateFnUtils from '@date-io/date-fns';

interface IFormSectionProps {
  total?: number;
  title: string;
}

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgba(191, 191, 191, 0.15)',
    boxShadow: '0 2px 6px 0 hsla(0, 0%, 0%, 0.2)',
    height: 'auto',
    padding: '20px'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  totalContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px'
  },
  total: {
    background: '#fff',
    boxShadow: '0 2px 6px 0 hsla(0, 0%, 0%, 0.2)',
    padding: '15px',
    fontWeight: 'bold'
  }
});

const FormSection: React.FC<IFormSectionProps> = ({
  children,
  title,
  total
}) => {
  // Hooks
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <MuiPickersUtilsProvider utils={DateFnUtils}>
        {children}
      </MuiPickersUtilsProvider>
      {total !== undefined ? (
        <section className={classes.totalContainer}>
          <div className={classes.total}>
            <span>
              {total.toLocaleString('en-IE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </div>
        </section>
      ) : (
        undefined
      )}
    </div>
  );
};

export default FormSection;
