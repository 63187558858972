export enum FilterMode {
  All,
  Adult,
  Junior,
  Group,
  IndividualOnline,
  JuniorOnline,
  KidsVirtualCamps,
  TeenVirtualCamps,
}
