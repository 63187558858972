import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

// Components
import { FormSection } from '../components';

// Images
import Logo from '../assets/images/logo.png';

const useStyle = makeStyles({
  container: {
    margin: 'auto',
    width: '50%'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px'
  },
  img: {
    margin: '40px 0 20px 0'
  }
});

const apiUrl = process.env.REACT_APP_API_URL || "";

interface ISuccessPageProps extends RouteComponentProps { }

const SuccessPage: React.FC<ISuccessPageProps> = ({ location }) => {
  const classes = useStyle();
  const [reference, setReference] = useState('');

  const params = queryString.parse(location.search);

  useEffect(() => {
    const getReference = async () => {
      if (reference === '') {
        const result = await axios.get(
          `${apiUrl}confirmation?session_id=${params.session_id}`
        );
        setReference(result.data.bookingRef);
      }
    };

    getReference();
  }, [params.session_id, reference]);

  return reference ? (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={Logo} alt="Logo"></img>
      </div>
      <FormSection title="">
        <p>Thank you for your provisional booking.</p>
        <p>
          A summary of booking information has been sent to you by email and a
          member of our team will be in touch shortly to confirm the
          availability for your dates and confirm your booking.
        </p>
        <p>Your reference number is {reference}</p>
        <p>Thanks,</p>
        <p>ICE Team</p>
      </FormSection>
    </div>
  ) : (
      <div></div>
    );
};

export default SuccessPage;
