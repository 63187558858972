import React from 'react';
import axios from 'axios';

// Components
import { FormSection } from '../';

declare global {
  interface Window {
    Stripe: any;
  }
}

const apiUrl = process.env.REACT_APP_API_URL || "";

const Payment = () => {
  const stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);

  const handleButtonClick = async () => {
    const result = await axios.post(`${apiUrl}createSession`, {
      email: 'rob@newcourtas.ie',
      amount: 20500,
      currency: 'eur'
    });

    if (result.status === 200) {
      stripe
        .redirectToCheckout({
          sessionId: result.data.id
        })
        .then((result: any) => console.log(result));
    }
  };

  return (
    <div className="checkout">
      <FormSection title="Payment">
        <button onClick={handleButtonClick}>Pay with Card</button>
      </FormSection>
    </div>
  );
};

export default Payment;
